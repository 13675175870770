<template>
  <div class="home-box">
    <!-- 顶部logo -->
    <div class="header">
      <img src="../assets/logo.svg" alt="" />
    </div>
    <!-- 平台信息 -->
    <div class="content">
      <div class="platform-info">
        <div
          class="detail"
          v-for="(item, index) in platformDataList"
          :key="item.value"
          :style="{ display: curIndex == index ? 'flex' : 'none' }"
        >
          <div class="detail-info">
            <div class="detail-title detail-animation">{{ item.title }}</div>
            <div class="detail-version detail-animation">
              {{ item.version }}
            </div>
            <div class="detail-version detail-animation">
              {{ item.support }}
            </div>
            <div class="detail-service detail-animation">
              <div
                class="service-item"
                v-for="(serviceItem, serviceIndex) in serviceList"
                :key="serviceIndex"
              >
                <img src="../assets/icon-success.png" alt="" />
                <span>{{ serviceItem }}</span>
              </div>
            </div>
            <div
              class="download-btn detail-animation"
              v-if="item.value == 'android' || item.value == 'windows'"
            >
              <!-- 这里写下载链接 -->
              <a :href="'https://www.pxdatarool.top:8443/download/'+item.pkg" target="_blank">Download</a>
            </div>
            <div
              class="download-btn download-btn2 detail-animation"
              v-if="item.value == 'ios' || item.value == 'android' || item.value == 'mac'"
            >
              <!-- 这里写下载链接 -->
              <a href="itms-apps://itunes.apple.com/app/com.loadx.app" target="_blank" v-if="item.value == 'ios' || item.value == 'mac'">
                <img
                  src="../assets/apple-store-btn.png"
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.tun.loadx" target="_blank" v-if="item.value == 'android'">
                <img
                  src="../assets/google-play-btn.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="detail-img">
            <img class="detail-animation" :src="item.image" alt="" />
          </div>
        </div>
      </div>

      <div class="platform-list">
        <div
          class="platform-item"
          v-for="(item, index) in platformList"
          :key="item.value"
          @click="handleChangePlatform(index)"
          @mouseover="handleMouseHover(index)"
          @mouseout="handleMouseOut"
        >
          <img
            :src="
              curIndex == index || hoverIndex == index ? item.icon1 : item.icon2
            "
          />
          <span
            :style="{
              color: curIndex == index || hoverIndex == index ? '#333' : '',
            }"
          >
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>

    <!-- 底部版权信息 -->
    <div class="footer">
      <div class="banquan">©2023 Loadx. copyright</div>
    </div>
  </div>
</template>

<script>
import "../css/Home.css";

export default {
  data() {
    return {
      // 平台信息列表
      platformDataList: [
        {
          value: "ios",
          title: "iPhone/iPad",
          version: "Latest version: iOS 17",
          support: "Minimum version: iOS 15",
          image: require("@/assets/ios.png"),
          pkg:"",
        },
        {
          value: "android",
          title: "Android",
          version: "Latest version: Android 14",
          support: "Minimum version: Android 9",
          image: require("@/assets/android.png"),
          pkg:"Loadx-android-v2.apk",
        },
        {
          value: "windows",
          title: "Windows",
          version: "Latest version: Windows 11",
          support: "Minimum version: Windows 10",
          image: require("@/assets/windows.png"),
          pkg:"Loadx-windows-v2.zip",
        },
        {
          value: "mac",
          title: "Mac",
          version: "Latest version: 13.6",
          support: "Minimum version: macOS 12.1",
          image: require("@/assets/mac.png"),
          pkg:"",
        },
      ],
      // 平台列表，icon1-选中图标，icon2-未选中图标，label-文字
      platformList: [
        {
          value: "ios",
          icon1: require("@/assets/icon-ios.svg"),
          icon2: require("@/assets/icon-ios2.svg"),
          label: "iPhone/iPad",
        },
        {
          value: "android",
          icon1: require("@/assets/icon-android.svg"),
          icon2: require("@/assets/icon-android2.svg"),
          label: "Android",
        },
        {
          value: "windows",
          icon1: require("@/assets/icon-windows.svg"),
          icon2: require("@/assets/icon-windows2.svg"),
          label: "Windows",
        },
        {
          value: "mac",
          icon1: require("@/assets/icon-mac.svg"),
          icon2: require("@/assets/icon-mac2.svg"),
          label: "Mac",
        },
      ],
      // 当前选中平台
      curIndex: 3,
      hoverIndex: null,
      serviceList: [
        "One-click connection, no manual configuration required",
        "Choose from thousands of routes around the world",
        "Traffic encryption, zero log policy",
        "7*16 hours customer service support",
      ],
    };
  },
  methods: {
    // 查看不同平台信息
    handleChangePlatform(index) {
      if (index == this.curIndex) return;
      this.curIndex = index;
    },
    handleMouseHover(index) {
      this.hoverIndex = index;
    },
    handleMouseOut() {
      this.hoverIndex = null;
    },
  },
};
</script>
